<template>
    <form class="form_dati_anag" action="next" method="POST">
        <div class="bg-yellow text-center lg:text-left mt-4 flex flex-wrap lg:flex-no-wrap items-center py-2 px-4 sm:px-12">
            <h1 class="text-center lg:text-left text-xl sm:text-3xl font-semibold text-gray-700 leading-tight px-6">{{ title }}</h1>
        </div>

        <div class="flex flex-wrap text-gray-700 p-4 pb-4 px-4 sm:px-12 rounded-b-lg">
            <p class="text-center text-lg sm:text-left mt-2 mb-4 w-full">{{ $t("Ora completa la richiesta con i tuoi dati") }}</p>
            <div class="w-full lg:w-1/2 lg:pr-2 mb-6">
                <FloatingLabelInput :name="`email`" :type="`text`" v-model="email" :mask="{ placeholder: '', greedy: false, casing: 'lower' }" :label="`E-mail (dove invieremo i documenti)`" :error="$v.email.$error"/>
                <div class="w-full text-red" v-if="$v.email.$error">
                    <small v-if="!$v.email.required">
                        {{ $t("Campo obbligatorio") }}
                    </small>
                    <small v-else-if="!$v.email.email">
                        {{ $t("Formato e-mail non valido") }}
                    </small>
                </div>
            </div>

            <div class="flex flex-wrap sm:flex-nowrap">
                <div class="relative sm:flex-shrink-0 mr-4 order-2 sm:order-1">
                    <input type="radio" v-model="privacy1" :value="true" class="form-radio" /><label>{{ $t("sì") }}</label>
                </div>
                <div class="relative sm:flex-shrink-0 sm:mr-4 order-3 sm:order-2">
                    <input type="radio" v-model="privacy1" :value="false" class="form-radio" /><label>{{ $t("no") }}</label>
                </div>
                <div class="w-full sm:flex-grow order-1 sm:order-3 mb-2" v-html="$t('Lette e comprese <a target=\'_blank\' href=\'https://www.cofidis.it/it/download/Informativa-Privacy.pdf\' class=\'underline\'>qui</a> l\'Informativa Privacy, resa ai sensi degli artt. 13 e 14 del Regolamento UE 679/2016, e l\’Informativa per tutti gli Operatori di SIC - Sistema di Informazioni Creditizie -, resa ai sensi dell’art. 6 del Codice di Condotta per i Sistemi Informativi gestiti da soggetti privati in tema di crediti al consumo, affidabilità e puntualità nei pagamenti, consapevole che, in mancanza di tale conferma, Cofidis non potrà dar corso alle operazioni o servizi richiesti. Puoi gestire i seguenti consensi facoltativi:')">
                </div>
            </div>
            <div class="w-full mb-4 text-red">
                <div v-if="$v.privacy1.$error && !$v.privacy1.required">
                    {{ $t("Campo obbligatorio") }}
                </div>
                <div v-if="privacy1 === false">
                    {{ $t("Non è possibile proseguire senza questo consenso.") }}
                </div>
            </div>
            <div class="flex flex-wrap sm:flex-nowrap">
                <div class="relative sm:flex-shrink-0 mr-4 order-2 sm:order-1">
                    <input type="radio" v-model="privacy2" :value="true" class="form-radio" /><label>{{ $t("sì") }}</label>
                </div>
                <div class="relative sm:flex-shrink-0 sm:mr-4 order-3 sm:order-2">
                    <input type="radio" v-model="privacy2" :value="false" class="form-radio" /><label>{{ $t("no") }}</label>
                </div>
                <div class="w-full sm:flex-grow order-1 sm:order-3 mb-2" v-html="$t('Acconsento al trattamento dei miei dati personali per finalità di marketing per prodotti e servizi di Cofidis, di società appartenenti al Gruppo di Cofidis ed al Gruppo Crédit Mutuel, o di società terze')">
                </div>
            </div>
            <div class="w-full mb-4 text-red">
                <div v-if="$v.privacy2.$error && !$v.privacy2.required">
                    {{ $t("Campo obbligatorio") }}
                </div>
                <div v-if="privacy2 === false">
                    {{ $t("Attenzione! Se scegli “no” non potrai partecipare alle nostre attività promozionali, ai concorsi, né ricevere offerte da Cofidis per i propri prodotti o servizi o per quelli di società terze.") }}
                </div>
            </div>
            <div class="flex flex-wrap sm:flex-nowrap">
                <div class="relative sm:flex-shrink-0 mr-4 order-2 sm:order-1">
                    <input type="radio" v-model="privacy3" :value="true" class="form-radio" /><label>{{ $t("sì") }}</label>
                </div>
                <div class="relative sm:flex-shrink-0 sm:mr-4 order-3 sm:order-2">
                    <input type="radio" v-model="privacy3" :value="false" class="form-radio" /><label>{{ $t("no") }}</label>
                </div>
                <div class="w-full sm:flex-grow order-1 sm:order-3 mb-2" v-html="$t('Acconsento al trattamento dei miei dati personali per finalità di profilazione delle mie preferenze, caratteristiche, abitudini o scelte di consumo al fine di ricevere offerte, promozioni ed informazioni che tengano conto dei miei interessi')">
                </div>
            </div>
            <div class="w-full mb-4 text-red">
                <div v-if="$v.privacy3.$error && !$v.privacy3.required">
                    {{ $t("Campo obbligatorio") }}
                </div>
                <div v-if="privacy3 === false">
                    {{ $t("Attenzione! Se scegli “no” non potremo proporti offerte e servizi personalizzati che tengano conto delle tue esigenze ed interessi specifici.") }}
                </div>
            </div>
            <div class="flex flex-wrap sm:flex-nowrap">
                <div class="relative sm:flex-shrink-0 mr-4 order-2 sm:order-1">
                    <input type="radio" v-model="privacy4" :value="true" class="form-radio" /><label>{{ $t("sì") }}</label>
                </div>
                <div class="relative sm:flex-shrink-0 sm:mr-4 order-3 sm:order-2">
                    <input type="radio" v-model="privacy4" :value="false" class="form-radio" /><label>{{ $t("no") }}</label>
                </div>
                <div class="w-full sm:flex-grow order-1 sm:order-3 mb-2" v-html="$t('Acconsento al trattamento dei miei dati personali per comunicazione a terzi, a società appartenenti al Gruppo Cofidis ed al Gruppo Crédit Mutuel, per proprie finalità di marketing')">
                </div>
            </div>
            <div class="w-full mb-4 text-red">
                <div v-if="$v.privacy4.$error && !$v.privacy4.required">
                    {{ $t("Campo obbligatorio") }}
                </div>
                <div v-if="privacy4 === false">
                    {{ $t("Attenzione! Se scegli “no” non potrai ricevere le offerte speciali da parte dei nostri partner.") }}
                </div>
            </div>
        </div>

        <div class="form-footer text-center relative mb-6">
            <button @click="submit" type="button" class="submit">continua</button>
        </div>
    </form>
</template>
<script>
import { mapUserData } from "@/utils";
import { required, email } from "vuelidate/lib/validators";
import FloatingLabelInput from "@/components/FloatingLabelInput";

export default {
    components: { FloatingLabelInput },
    mounted() {
        this.$ga.pageview()
    },
    data() {
        return {
            showExplanation: false,
            literals: ['un','due','tre']
        };
    },
    computed: {
        ...mapUserData(["loans_count", "email", "privacy1", "privacy2", "privacy3", "privacy4", "politics"]),
        email: {
            get() {
                return this.$store.state.userData.email;
            },
            set(value) {
                return this.$store.commit("setUserData", { key: "email", value });
            }
        },
        title() {
            if(this.loans_count > 1)
                return `Hai scelto di consolidare ${this.literals[this.loans_count - 1]} prestiti`
            else
                return `Hai scelto di consolidare un prestito`
        }
    },
    validations: {
        email: {
            required,
            email
        },
        privacy1: {
            required
        },
        privacy2: {
            required
        },
        privacy3: {
            required
        },
        privacy4: {
            required
        }
    },
    methods: {
        submit() {
            this.$v.$touch()
            this.focusFirstError()
            if (!this.$v.$invalid && this.privacy1 === true) {
                this.$store.dispatch("event", { name: "ConsolidamentoEmail" });
                this.$store.dispatch("sendData", { step: "dati_privacy_consolidamento" });
                this.$store.dispatch("redirect", { step: "email", route: "InfoLoans" });
            }
        }
    }
};
</script>