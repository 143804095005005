<template>
    <footer class="bg-white text-xs pb-6">
        <div class="container mx-auto px-5 py-10 relative">
            <ScrollTopButton />
            <h4 class="mb-2 font-bold text-sm">{{ $t('Informazioni legali') }}</h4>
            <p class="mb-5">
                {{ $t("Messaggio pubblicitario con finalità promozionale. Le richieste di prestito personale sono soggette a valutazione da parte di Cofidis S.A.. CiviBank, Banca di Cividale S.p.A opera in qualità di intermediario del credito per la promozione e il collocamento di tale prodotto in virtù del rapporto di collaborazione con Cofidis S.A., senza vincolo di esclusiva a favore di quest’ultima e senza costi aggiuntivi per il cliente. Per il TAEG, per le condizioni contrattuali del Prodotto e per quanto non espressamente indicato, è necessario fare riferimento al documento Informazioni Europee di Base sul Credito ai Consumatori (SECCI) rilasciabile in forma personalizzata dalle Filiali della Banca.") }}<br>
                {{ $t("Il presente messaggio pubblicitario ha finalità promozionali e non costituisce sollecitazione all’investimento. Per le condizioni economiche relative ai servizi citati consultare il Foglio Informativo.") }}<br>
                {{ $t("Al fine di gestire le tue spese in modo responsabile Cofidis S.A. ti ricorda, prima di sottoscrivere il contratto, di prendere visione di tutte le condizioni economiche e contrattuali, facendo riferimento alle Informazioni Europee di Base sul Credito ai Consumatori (SECCI) sul sito www.cofidis.it sezione trasparenza. L’erogazione dei prestiti richiesti, gli importi finanziabili e i massimi erogabili sono soggetti all’approvazione di Cofidis S.A.") }}<br>
                {{ $t("Esempio rappresentativo di un prestito personale di importo pari a € 3.000 (Importo totale del credito) da rimborsare in 36 mesi, con rate mensili di € 100,00, TAN fisso 9,980% - TAEG 17,07%. Spese accessorie: spesa di istruttoria € 100,00, spesa di incasso rata € 5,00, imposta di bollo / imposta sostitutiva sul contratto € 7,75, spese invio Rendiconto € 1,20 oltre imposta di bollo pari a € 0,00 per importi superiori a € 77,47 per ogni invio. Totale da rimborsare € 3.790,15 (Importo totale dovuto).") }}<br>
                {{ $t("In funzione dell'importo richiesto, le rate mensili di rimborso del prestito personale possono variare da numero minimo di 12 ad un massimo di 84 rate.") }}
            </p>
            <div class="underline mb-5"><a href="https://www.cofidis.it/trasparenza/" target="_blank">{{ $t("Trasparenza") }}</a></div>
            <div class="row">
                <div class="float-left lg:w-9/12 w-full">
                    <div class="font-bold text-sm">Cofidis Italia</div>
                    <div class="mb-2">{{ $t("© Copyright ") + year }}</div>
                    <p class="mb-2 text-xs">
                        {{ $t("Capitale Sociale 67.500.000€ i.v., Iscritta all’Albo Banche ed altri intermediari esteri abilitati in Italia al nr. 8101 - Società soggetta a direzione e coordinamento di Banque Fédérative du Crédit Mutuel S.A. – soggetta alla vigilanza della Banca di Francia (Autorité de Contrôle Prudentiel et de Résolution) e, in qualità di succursale italiana di banca comunitaria, alla vigilanza della Banca d’Italia, per le materie a questa demandate dalle vigenti disposizioni normative. Abilitata all’esercizio dell’attività di intermediazione assicurativa in Italia, iscritta nell’Elenco degli Intermediari dell’Unione Europea annesso al Registro degli Intermediari Assicurativi nr. UE00012402, soggetta a vigilanza dell’ AUTORITÉ DE CONTROLE PRUDENTIEL (ACP).") }}
                    </p>
                    <div class="mb-2">
                        <a href="https://www.cofidis.it/it/privacy-policy.html" class="underline" target="_blank">Privacy Policy</a> |
                        <a href="https://cofidis-agid.s3.eu-central-1.amazonaws.com/Dichiarazione+di+non+accessibilita%CC%80+Prestito+Banca.pdf" class="underline" target="_blank">Accessibilità: non conforme</a>
                    </div>
                </div>
                <div class="float-right lg:w-3/12 w-full text-center">
                    <img src="@/views/themes/civibank/images/Civibank_Logo.svg" alt="Banca CiviBank Logo" class="m-auto my-6 md:mb-10" />
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
import ScrollTopButton from '@/components/scrollTopButton.vue';

export default {
    data() {
        return {
            year: null
        }
    },
    created() {
        this.year = new Date().getFullYear();
    },
    components: { ScrollTopButton } 
};
</script>
