<template>
    <main class="flex-grow">
        <Loader v-if="loading" />
        <div v-else>
            <div class="text-sm sm:text-base leading-tight text-gray-700 w-fit mx-auto pt-20">
                <div class="bg-white pt-10 pb-4 px-5 mx-4 text-center rounded-3xl max-w-xl text-gray-700 shadow-xl">
                    <i class="icon smile block mx-auto"></i>
                    <p class="text-3xl sm:text2xl font-semibold md:w-2/3 mx-auto mt-6">Grazie per la tua risposta</p>
                    <Stars :count="maxVote" :vote="vote" />
                    <p class="mt-2 mb-6 md:w-2/3 mx-auto">{{ name }}, ti ringraziamo per la tua valutazione al Servizio Relazione Clienti di Cofidis.</p>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import Loader from "@/components/Loader"
import Stars from "@/components/Stars";
import { mapUserData } from "@/utils";
import axios from "axios";
import moment from "moment/moment";

const redirectHomepage = () => {    
    location.href = '/survey-cs'
}

export default {
    name: "Home",
    components: { Loader, Stars },
    data() {
        return {
            loading: true,
            vote: null,
            minVote: 1,
            maxVote: 5,
        };
    },
    methods: {
        async loadClientData(data) {
            return new Promise((resolve, reject) => {
                axios.post(this.crmUrl, data)
                    .then(function(response) {
                        if(response.data && response.data.dt_end_scs)
                            resolve(response.data)
                        else
                            reject(false)
                    })
                    .catch(function() {
                        reject(false)
                    })
            })
        },
        reloadOnBrowserBack() {            
            window.popStateDetected = false
            window.addEventListener('popstate', redirectHomepage)
        }
    },
    computed: {
        ...mapUserData([
            "name"
        ]),
        crmUrl() {
            return this.$store.state.crmUrl;
        }
    },
    async created() {
        this.reloadOnBrowserBack()

        // get params
        const urlParts = this.$store.state.urlParts
        let userId, codeCampaign, trackCode;

        if(urlParts[1] && urlParts[2]) {
            userId = urlParts[1]
            codeCampaign = urlParts[2]
            trackCode = urlParts[3] ?? null
        }
        else {
            userId = localStorage.getItem('survey_cs_user_id')
            codeCampaign = localStorage.getItem('survey_cs_code_campaign')
            trackCode = localStorage.getItem('survey_cs_track_code') ?? null
            if(trackCode === "null") trackCode = null;
        }

        // get vote from urlVars
        this.vote = parseInt(this.$store.state.urlVars.vote);

        if (this.vote >= this.minVote && this.vote <= this.maxVote) {

            // get client data
            try {

                localStorage.setItem('survey_cs_user_id', userId)
                localStorage.setItem('survey_cs_code_campaign', codeCampaign)
                if(trackCode) localStorage.setItem('survey_cs_track_code', trackCode)

                const data = await this.loadClientData({userId, codeCampaign, trackCode});
                this.$store.commit("setUserData", { key: 'user_id', value: userId });
                this.$store.commit("setUserData", { key: 'client_id', value: data.cliente_id });
                this.$store.commit("setUserData", { key: 'code_campaign', value: codeCampaign });
                this.$store.commit("setUserData", { key: 'name', value: data.Nome });
                this.$store.commit("setUserData", { key: 'surname', value: data.Cognome });
                this.$store.commit("setUserData", { key: 'email', value: data.EMail });
                this.$store.commit("setUserData", { key: 'fiscal_code', value: data.CF });
                this.$store.commit("setUserData", { key: 'call_date', value: moment(data.data_call).locale("it").format('DD-MM-YYYY') });
                this.$store.commit("setUserData", { key: 'operatore', value: data.operatore });
                this.$store.commit("setUserData", { key: 'dt_end_scs', value: moment(data.dt_end_scs).locale("it").format('DD-MM-YYYY') });
                this.$store.commit("setUserData", { key: 'vote', value: this.vote});

                // pending procedure
                if(data.page_block && data.page_block === 'evaluation') {
                    this.$store.dispatch("redirect", {step: "homepage", route: "RequestPending"});
                    return;
                }

                // offer expired
                if(moment(data.dt_end_scs).endOf('day') < moment()) {
                    this.$store.dispatch("redirect", {step: "homepage", route: "RequestKO"});
                    return;
                }

                await this.$store.dispatch("sendData", {step: "enter_landing"});
                this.$store.dispatch("sendData", { step: "vote", method: "POST" });

                } catch (error) {
                    this.$store.dispatch("redirect", {step: "homepage", route: "RequestKO"});
                }
        } else {
            this.$store.dispatch("redirect", {step: "homepage", route: "RequestKO"});
        }

        this.loading = false
    },
};
</script>