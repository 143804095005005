class Append {
    enter_landing(payload, {state}) {
        payload['CF'] = state.userData.fiscal_code
        payload['userId'] = state.userData.user_id
        payload['codeCampaign'] = state.userData.code_campaign
    }

    dati_anag(payload, {state}) {
        delete(payload['EMail'])
        delete(payload['CF'])
        delete(payload['Rate'])
        delete(payload['Importo'])
        payload['stepNumber'] = 0
        payload['Prod'] = state.userData.prod
    }

    dati_lav(payload, {state}) {
        delete(payload['Attivita'])
        delete(payload['Settore'])
        delete(payload['Occupazione'])
        delete(payload['Contratto'])
        delete(payload['Mensilita'])
        delete(payload['Occupato_Dal'])
        delete(payload['Occupato_Al'])
        delete(payload['Famigliari'])
        delete(payload['Famigliari_Reddito'])
        payload['EMail'] = state.userData.email
        payload['Iban'] = state.userData.iban
        payload['Importo'] = state.userData.importo
        payload['Fido'] = state.userData.importo
        payload['Reddito'] = state.userData.monthly_income
        payload['stepNumber'] = 1
        // request can't be forwarded
        if(state.userData.can_request === false) {
            payload['userId'] = state.userData.user_id
            payload['codeCampaign'] = state.userData.code_campaign
            payload['Esito'] = 'reddito_not_allowed'
        }
    }

    dati_pers_4(payload) {
        payload['stepNumber'] = 2
    }

    dati_privacy(payload, {state}) {
        if(state.userData.document_expired) {
            payload['stepNumber'] = 3
        }
        else {
            payload['stepNumber'] = 2
        }
    }
}
export default new Append();