<template>
<div class="bg-black bg-opacity-30 fixed w-screen h-screen inset-0 flex items-center justify-center z-50">
    <div class="modal-wrapper relative bg-white p-4 mx-4 text-center rounded-3xl w-full mb-64 max-w-xl text-gray-700 shadow-xl">
        <CloseButton v-on:close="$emit('close-modal')" />
        <p class="text-center text-lg font-semibold mt-4">È un po' che non clicchi nulla...</p>
        <h1 class="text-3xl font-semibold md:w-2/3 mx-auto mt-1">
            {{ $t("Serve aiuto?") }}
        </h1>
        <div class="w-full flex justify-center py-8">
            <a v-if="isMobile" :href="whatsAppUrl" target="_blank">
                <div class="w-fit cursor-pointer bg-green-1 pl-6 pr-10 py-2 lg:pl-4 rounded-full gap-4 inline-flex items-center">
                    <img height="40" width="40" src="@/assets/images/icon-wa.svg" />
                    <p class="text-white text-left pb-1 font-semibold text-lg leading-tight">
                        Inizia la chat con un<br> nostro consulente
                    </p>
                </div>
            </a>
            <div v-else class="w-fit flex flex-wrap text-left border-green-1 bg-white border-2 p-2 rounded-xl">
                <div class="ml-2 mr-3 mt-2">
                    <img height="45" width="45" src="@/assets/images/icon-wa-green.svg" />
                </div>
                <div class="flex flex-col mt-2 justify-start mr-6">
                    <p class="text-lg font-bold leading-tight">Inizia la chat con un<br> nostro consulente</p>
                    <p class="text-base leading-tight mt-2">Inquadra il QR code<br> e ricevi subito aiuto</p>
                </div>
                <div>
                    <img width="110" height="110" src="@/assets/images/wa-qr-code.png" />
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import { mapGetters } from 'vuex';
import CloseButton from '@/components/CloseButton.vue';

export default {
    name: "ModalInactive",
    computed: {
        ...mapGetters(['isMobile', 'whatsAppUrl'])
    },
    components: { CloseButton }
}
</script>