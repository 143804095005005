<template>
    <FormBox title="Protezione del pagamento" @submit="onSubmit()" @decline="goBack()" >
        
        <ProgressPz slot="form-header" progress="60" />

        <p class="mb-4 text-base">
            Per poter proteggere il tuo prestito occorre compilare il presente
            questionario mirato ad acquisire le informazioni necessarie al fine di
            proporti un contratto coerente alle tue esigenze ed i tuoi bisogni
            assicurativi per aiutarci a offrirti le garanzie assicurative maggiormente rispondenti a tali bisogni ed esigenze. La mancanza di tali informazioni
            ostacola la corretta valutazione del fabbisogno assicurativo.
        </p>
        <ConsentItem v-model="consent_main" :v="$v.consent_main">
            Ho letto e compreso le condizioni del contratto assicurativo contenute nel
            <a target="_blank" href="https://www.cofidis.it/it/download/trasparenza/IT_SetInformativo_fullACM.pdf" class="underline">set informativo</a>
            – comprensivo di franchigie, esclusioni e limitazioni - e dichiaro di
            essere interessato alla copertura assicurativa proposta. <i>Confermo inoltre
            che le risposte da me fornite di seguito rispondono esattamente alla mia
            attuale situazione.</i>
        </ConsentItem>
        <p class="mb-4">
            Il sottoscritto prende atto del fatto che in caso di risposta negativa
            anche ad una sola delle domande poste nel questionario, ovvero in caso di
            rifiuto di rispondere alle domande, non sarà possibile procedere alla
            sottoscrizione della polizza.
        </p>
        <template v-if="gPackage">
            <ConsentItem v-model="consent1" :v="$v.consent1">
                Confermi di non essere attualmente in malattia o infortunio e di non
                aver avuto una assenza per malattia o infortunio di oltre 30 gg
                consecutivi negli ultimi 2 anni?
            </ConsentItem>
            <ConsentItem v-model="consent2" :v="$v.consent2">
                Confermi di non essere titolare di una pensione/rendita di invalidità?
            </ConsentItem>
            <ConsentItem v-model="consent3" :v="$v.consent3">
                Confermi di non avere altre polizze assicurative su questo
                finanziamento?
            </ConsentItem>
            <ConsentItem v-model="consent4" :v="$v.consent4">
                Confermi di essere interessato a una copertura per tutta la durata
                residua del finanziamento che prevede limiti, franchigie ed esclusioni,
                che aiuti te o i tuoi eredi a pagare il debito residuo in caso di
                Decesso, Perdita Totale ed Irreversibile di Autonomia e che inoltre ti
                rimborsi almeno parte delle rate in caso di Perdita Involontaria di
                Impiego e il debito residuo in caso di Inabilità Temporanea Totale al
                lavoro a seguito di malattia o infortunio?
            </ConsentItem>
            <ConsentItem v-model="consent5" :v="$v.consent5">
                Sei in cerca di una copertura assicurativa per perdita totale e
                irreversibile di autonomia che operi per i soli casi di grandi
                invalidità?
            </ConsentItem>
            <ConsentItem v-model="consent6" :v="$v.consent6">
                Sei in cerca di una copertura assicurativa per Perdita Involontaria di
                Impiego che operi solo in caso di licenziamento per giustificato motivo
                oggettivo indennizzando fino a un massimo di 9 rate del finanziamento e
                per un massimo di due sinistri?
            </ConsentItem>
            <ConsentItem v-model="consent7" :v="$v.consent7">
                Sei in cerca di una copertura assicurativa per Inabilità temporanea
                totale al lavoro a seguito di malattia o infortunio che in caso di
                sinistro fornisca una somma pari alle rate residue del finanziamento?
            </ConsentItem>
        </template>
        <template v-else>
            <ConsentItem v-model="consent1" :v="$v.consent1">
                Confermi di non avere altre polizze assicurative su questo
                finanziamento?
            </ConsentItem>
            <ConsentItem v-model="consent2" :v="$v.consent2">
                Confermi di essere interessato a una copertura per tutta la durata
                residua del finanziamento che prevede limiti, franchigie ed esclusioni,
                che aiuti te o i tuoi eredi a pagare il debito residuo in caso di
                Decesso e Perdita Totale ed Irreversibile di Autonomia?
            </ConsentItem>
            <ConsentItem v-model="consent3" :v="$v.consent3">
                Sei in cerca di una copertura assicurativa per perdita totale e
                irreversibile di autonomia che operi per i soli casi di grandi
                invalidità?
            </ConsentItem>
        </template>

    </FormBox>
</template>
<script>
import FormBox from "./FormBox";
import ProgressPz from "./ProgressPz";
import { mapGetters } from "vuex";
import { mapUserData } from "@/utils";
import ConsentItem from "@/components/ConsentItem";
import { required, requiredIf, helpers } from "vuelidate/lib/validators";

const mustBeTrue = value => !helpers.req(value) || value === true;

export default {
    components: { FormBox, ProgressPz, ConsentItem },
    mounted() {
        this.$store.dispatch("sendData", { step: "assicurazione_consent" })
    },
    computed: {
        ...mapGetters(["insurancePackage", "insuranceRate"]),
        ...mapUserData([
          "employment_type", 
          "insurance", 
          "insurance_pack", 
          "insurance_amount",
          "consent_main",
          "consent1",
          "consent2",
          "consent3",
          "consent4",
          "consent5",
          "consent6",
          "consent7",
        ]),
        gPackage() {
            return this.insurancePackage == "G";
        }
    },
    validations: {
        consent_main: {
            required,
            mustBeTrue
        },
        consent1: {
            required,
            mustBeTrue
        },
        consent2: {
            required,
            mustBeTrue
        },
        consent3: {
            required,
            mustBeTrue
        },
        consent4: {
            required: requiredIf(function() {
                return this.gPackage;
            }),
            mustBeTrue
        },
        consent5: {
            required: requiredIf(function() {
                return this.gPackage;
            }),
            mustBeTrue
        },
        consent6: {
            required: requiredIf(function() {
                return this.gPackage;
            }),
            mustBeTrue
        },
        consent7: {
            required: requiredIf(function() {
                return this.gPackage;
            }),
            mustBeTrue
        }
    },
    watch: {
        consent_main(val) {
            if (val) {
                for (let i = 1; i <= 7; i++) {
                    this[`consent${i}`] = true;
                }
            }
            else this.insurance = false
        },
        consent1(val) { if (!val) this.insurance = false },
        consent2(val) { if (!val) this.insurance = false },
        consent3(val) { if (!val) this.insurance = false },
        consent4(val) { if (!val) this.insurance = false },
        consent5(val) { if (!val) this.insurance = false },
        consent6(val) { if (!val) this.insurance = false },
        consent7(val) { if (!val) this.insurance = false }
    },    
    methods: {
        onSubmit() {
            this.$v.$touch()
            this.focusFirstError()
            if (!this.$v.$invalid && this.consent_main === true) {                
                this.$store.commit('setInsurance', true)
                this.$store.dispatch("redirect", { step: "insurance_consent", route: "PersonalDataPz" });
            }
        }
    }
};
</script>