<template>
    <div :id="sovDivId"></div>
</template>
<style>
@media (max-width: 678px) {
    .sov-overlay {
        opacity: 0;
        transition: opacity 0.3s;
        pointer-events: none;
    }

    .sov-overlay.visible {
        opacity: 1;
        pointer-events: all;
    }
}
</style>
<script>

export default {
    name: "Sovendus",
    props: {
        id: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            trafficSourceNumber: 8111,
            trafficMediumNumber: 1,
            mobileDelayTime: 3000,
            scriptId: 'sovendus-integration-script',
            scriptSrc: 'https://api.sovendus.com/sovabo/common/js/flexibleIframe.js'
        }
    },
    computed: {
        sovDivId() {
            return `sovendus-integration-container-${this.id}`
        },
    },
    created() {
        window.sovIframes = window.sovIframes || []
        window.sovIframes.push({
            trafficSourceNumber: this.trafficSourceNumber,
            trafficMediumNumber: this.trafficMediumNumber,
            iframeContainerId: this.sovDivId,
            integrationType: "vue-1.0.9"
        })
        const script = document.createElement('script')
        script.async = true
        script.id = this.scriptId
        script.src = this.scriptSrc
        script.type = 'text/javascript'
        document.body.appendChild(script)

        // on mobile show the widget with a delay
        setTimeout(() => {
            document.querySelector('.sov-overlay')?.classList.add('visible')
        }, this.mobileDelayTime)

    },
    mounted() {
        this.$on('routeLeave', this.handleRouteLeave);
    },
    beforeDistroy() {
        this.$off('routeLeave', this.handleRouteLeave);
    },
    methods: {
        handleRouteLeave() {
            window.sovIframes = []
            document.getElementById('sovloader-script')?.remove()
            document.getElementById(this.scriptId)?.remove()
        }
    }
}
</script>