<template>
    <div class="w-full">
        <FormCard @submit="onSubmit" title="CLUB">
            <p class="mb-4">
                Cofidis ti propone il servizio CLUB PLATINUM.
                Per 12 mesi puoi scegliere <strong>esperienze uniche in formula 2x1</strong>: paghi per uno e invita chi vuoi (ad esempio cinema, ristoranti e hotel)!
                <strong>Puoi beneficiare anche di sconti, buoni spesa e vantaggi del programma Lucky Days.</strong>
                Il tutto ad un prezzo esclusivo per i clienti Cofidis: circa {{ installment|num2 }} € al mese* applicati automaticamente alla tua rata.
                <a :href="infoClubUrl" target="_blank" class="underline">Scopri qui</a> tutti i vantaggi del CLUB PLATINUM.
            </p>
            <p class="mb-4">
                Prima di procedere, ti invitiamo a prendere visione del <a :href="rulesClubUrl" target="_blank" class="underline">Regolamento</a>
                che riporta nel dettaglio le caratteristiche del prodotto, nonché
                l’<a :href="privacyClubUrl" target="_blank" class="underline">Informativa Privacy</a>.
                Il servizio CLUB <strong>è facoltativo e non è necessario per ottenere il prestito o per ottenerlo a condizioni più vantaggiose.</strong>
            </p>
            <ClubOptionalConsents />
            <div slot="link" class="text-center text-gray-700 mt-4 mb-8">
                <button @click="onDecline()" class="underline">Procedi senza Club</button>
            </div>
        </FormCard>
        <portal to="sub-form">
            <DisclaimerText>
                *CLUB è un prodotto di Quattrolinee s.r.l. e distribuito da Cofidis grazie all'accordo con essa. L'importo esatto del costo del servizio sarà riportato nella documentazione contrattuale.
            </DisclaimerText>
        </portal>
    </div>
</template>
<style scoped type="text/css">
@media screen and (max-width: 639px) {
    div >>> .form-header { font-size: 1em }
}
</style>
<script>
import DisclaimerText from '@/components/DisclaimerText.vue';
import { CLUB_PLATINUM } from '../../constants';
import ClubOptionalConsents from '@/components/ClubOptionalConsents.vue';

export default {
    data() {
        return {
            infoClubUrl: 'https://cofidis-docs-club.s3.eu-south-1.amazonaws.com/Cofidis_Club_PLATINUM.pdf',
            rulesClubUrl: 'https://cofidis-docs-club.s3.eu-south-1.amazonaws.com/Regolamento_Servizio_CLUB.pdf',
            privacyClubUrl: 'https://cofidis-docs-club.s3.eu-south-1.amazonaws.com/Informativa_Servizio.pdf'
        }
    },
    mounted() {
        this.$ga.pageview()
        this.$store.commit("setUserData", { key: 'service_available', value: true });
        this.$store.commit("setUserData", { key: 'service_pack', value: CLUB_PLATINUM.code });
        this.$store.commit("setUserData", { key: 'service_amount', value: CLUB_PLATINUM.value/12 });

        this.$store.dispatch("sendData", { step: "club" })
    },
    computed: {
        installment() {
            const rate = +this.$store.state.userData.rate
            return parseFloat((CLUB_PLATINUM.value/rate) + 1).toFixed(2)
        }
    },
    methods: {
        onDecline() {
            this.$store.dispatch("redirect", { step: "club_ko", route: "ClubWarning" });
        },
        onSubmit() {
            this.$store.commit("setUserData", { key: 'service', value: true });
            this.$store.commit("setUserData", { key: 'service_pack', value: CLUB_PLATINUM.code });
            this.$store.commit("setUserData", { key: 'service_amount', value: CLUB_PLATINUM.value/12 });
            this.$store.dispatch("redirect", { step: "club_consent", route: "DeliveryData" });
        }
    },
    components: { ClubOptionalConsents, DisclaimerText }
};
</script>