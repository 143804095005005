<template>
  <input
    class="form-input uppercase autocomplete-input"
    v-bind="$attrs"
    @blur="handleBlur"
    @input="formatInput"
    ref="input"
    type="text"
  />
</template>

<script>
import autocomplete from "js-autocomplete";
export default {
  props: {
    search: {
      type: Function,
      required: true
    },
    value: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      engine: null
    };
  },
  watch: {
    value(val) {
      this.$refs.input.value = val;
    }
  },
  mounted() {
    this.$refs.input.value = this.value;
    this.engine = new autocomplete({
      selector: this.$refs.input,
      minChars: 2,
      delay: 200,
      cache: false,
      source: (term, response) => {
        response(this.search(term));
      },
      onSelect: (e, term) => {
        this.$emit("input", term);
      }
    });
  },
  methods: {
    handleBlur(e) {
      if (e.target.value !== this.value) {
        this.$emit("input", "");
        e.target.value = "";
      }
    },
    formatInput(event) {
      this.$refs.input.value = event.target.value.replace(/’/g, "'");
    }
  },
  destroyed() {
    this.engine.destroy();
  }
};
</script>

<style>
::placeholder {
  text-transform: none;
}
</style>
