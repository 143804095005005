import { extractFiscalCode } from "@/utils";

class Append {
    enter_landing(payload, {state}) {
        payload['CF'] = state.userData.fiscal_code
        payload['userId'] = state.userData.user_id
        payload['codeCampaign'] = state.userData.code_campaign
        payload['Fido'] = state.userData.max_amount
    }

    dati_anag(payload, {state}) {
        delete(payload['EMail'])
        delete(payload['CF'])
        delete(payload['Rate'])
        payload['stepNumber'] = 1
        payload['Prod'] = state.userData.prod
    }

    income(payload, {state}) {
        payload['EMail'] = state.userData.email
        payload['Iban'] = state.userData.iban
        payload['Importo'] = state.userData.importo
        payload['Reddito'] = state.userData.monthly_income
        payload['stepNumber'] = 2
        payload['service'] = state.userData.service
        payload['im_pacchetto'] = state.userData.service_amount
    }

    dati_pers_4(payload) {
        payload['stepNumber'] = 3
    }

    dati_metlife_1(payload, {state}) {
        const phone = state.userData.cellphone.substring(3).replace(/ +/g, "")
        const prv = /\(([^)]+)\)/.exec(state.userData.address_city);
        const cap = '0'.repeat(5 - state.userData.address_cap.toString().length) + state.userData.address_cap.toString()
        const matches = state.userData.address.match(/^(.*\S)\s+(\d+[A-Z]?)$/);
       
        payload['Indirizzo'] = matches[1].trim()
        payload['N'] = matches[2].trim()
        payload['Phone'] = phone
        payload['CAP'] = cap
        payload['Comune'] = state.userData.address_city
        payload['Prov'] = prv ? prv[1] : null
        payload['City'] = state.userData.address_city.slice(0, -5)
    }

    dati_metlife_2(payload, {state}) {
        const cf = extractFiscalCode(state.userData.fiscal_code);

        payload['Sex'] = cf.gender
        payload['Birthday_Day'] = cf.day
        payload['Birthday_Month'] = cf.month
        payload['Birthday_Year'] = cf.year
        payload['stepNumber'] = 3
    }

    dati_privacy(payload, {state}) {
        // request can't be forwarded
        if(state.userData.can_request === false) {
            payload['userId'] = state.userData.user_id
            payload['codeCampaign'] = state.userData.code_campaign
            payload['Esito'] = 'reddito_not_allowed'
        }
        payload['stepNumber'] = (state.userData.document_expired || !state.userData.can_request) ? 4 : 3;
    }
}
export default new Append();