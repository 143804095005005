<template>
    <div 
    @click="$emit('close')"
    class="absolute right-0 -top-2 transform -translate-y-9 shadow-lg rounded-full cursor-pointer"
>
    <img height="30" width="30" src="@/assets/images/icon-close-dark.svg" />
</div>
</template>
<script>
    export default {
        name: 'CloseButton'
    }
</script>