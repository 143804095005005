<template>
    <div>
        <FormCard @submit="onSubmit" title="Protezione del pagamento">
            <template v-if="insurancePackage == 'G'">
                <p class="my-4">
                    Con le informazioni che ci hai comunicato, Cofidis ti propone una
                    <b>copertura assicurativa</b> facoltativa per proteggere il tuo
                    finanziamento, con circa {{ getInsurancaRate }} euro di premio* in più
                    sulla rata mensile. Proteggerebbe te o i tuoi eredi rimborsando il
                    capitale residuo del prestito in caso di <b>Decesso, Perdita Totale e Irreversibile di Autonomia</b>
                    e il debito residuo in caso di Inabilità Temporanea Totale al lavoro a
                    seguito di malattia o infortunio. Nove rate in caso di
                    <b>Perdita Involontaria di Impiego.</b>
                </p>
                <p class="my-4">
                    Cliccando su CONTINUA dichiari di aver preso visione delle condizioni del contratto
                    contenute nel <a target="_blank" href="https://www.cofidis.it/it/download/trasparenza/IT_SetInformativo_fullACM.pdf" class="underline">set informativo</a>
                </p>
            </template>
            <template v-else>
                <p class="mt-4">
                    Con le informazioni che ci hai comunicato, Cofidis ti propone
                    una <b>copertura assicurativa</b> facoltativa per proteggere il tuo
                    finanziamento, con soli {{ getInsurancaRate }} euro di premio* in più
                    sulla rata mensile. Proteggerebbe te o i tuoi eredi rimborsando il
                    capitale residuo del prestito in caso di <b>Decesso e Perdita Totale e Irreversibile di Autonomia.</b>
                </p>
                <p class="my-4">
                    Cliccando su CONTINUA dichiari di aver preso visione della condizioni del contratto contenute nel <a target="_blank" class="underline" href="https://www.cofidis.it/it/download/trasparenza/IT_SetInformativo_fullACM.pdf">set informativo</a>
                </p>
            </template>
            <div slot="link" class="text-center text-gray-700 text-sm mt-4 mb-8">
                <router-link :to="{ name: 'LoanProtectionWarning' }" class="underline">Procedi senza proteggerti</router-link>
            </div>
        </FormCard>
        <portal to="sub-form">
            <DisclaimerText>
                *L'importo esatto del premio dell'assicurazione sarà riportato nella documentazione contrattuale.
            </DisclaimerText>
        </portal>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import { mapUserData } from "@/utils";
import DisclaimerText from "@/components/DisclaimerText.vue";

export default {
    components: { DisclaimerText },
    mounted() {
        this.$ga.pageview()
        this.$store.dispatch("event", { name: "LoadProtectionLoaded" });
        this.$store.dispatch("sendData", { step: "assicurazione" })

        this.$store.commit('setInsuranceAvailable', true)
        this.$store.commit('setInsurancePack', this.insurancePackage)
        this.$store.commit('setInsuranceRate', this.insuranceRate)
    },
    computed: {
        ...mapGetters(["insurancePackage", "insuranceRate"]),
        ...mapUserData(["insurance"]),
        getInsurancaRate() {
            return this.insuranceRate.replace('.',',')
        }
    },
    methods: {
        onSubmit() {
            this.$store.dispatch("event", { name: "LoanProtectionSubmit" });
            this.$store.dispatch("redirect", { step: "insurance", route: "LoanProtectionConsent" });
        }
    }
};
</script>
