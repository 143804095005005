<template>
    <div class="disclaimer-text w-11/12 sm:max-w-7xl mx-auto text-gray-700 -mt-4 mb-10 px-4">
        <p class="text-sm md:text-base italic">
            <slot></slot>
        </p>
    </div>
</template>
<script>
export default {
    name: "DisclaimerText"
}
</script>