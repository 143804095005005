<template>
    <div class="px-4 mx-auto lg:container text-sm sm:text-base leading-tight text-gray-700">
        <div class="px-2 py-6 text-center text-2xl sm:text-3xl font-semibold">
            Gentile <strong>{{ name }}</strong>, grazie per averci scelto!
        </div>
        <div class="max-w-4xl text-lg mx-auto p-4">
            <h1 class="text-2xl font-bold text-center mt-4 mb-8">
                Purtroppo non possiamo procedere con la tua richiesta di {{ type }}.
            </h1>
            <p class="mb-8 text-center">
                Ti ricordiamo però che potrai usufruire del servizio CLUB PLUS - esperienze uniche in formula 2x1 - e che il primo mese di accesso è gratuito.
            </p>
            <p class="mb-4 text-center">Riceverai tutte le informazioni via e-mail entro pochi minuti.</p>

            <div class="text-center mt-10">
                A presto<br />
                <strong>Il Team Cofidis</strong>
            </div>
            <div class="text-center mt-10">
                <span>Seguici anche su</span>
                <div class="social-links">
                    <a class="inline-block social-link p-4" target="_blank" rel="nofollow" href="https://www.facebook.com/CofidisItalia/">
                        <img class="h-12 mx-auto" src="@/assets/images/typ-fb.svg" />
                    </a>
                    <a class="inline-block social-link p-4" target="_blank" rel="nofollow" href="https://www.instagram.com/cofidisitalia/">
                        <img class="h-12 mx-auto" src="@/assets/images/typ-ig.svg" />
                    </a>
                </div>
            </div>
        </div>
        <Sovendus ref="sovendus" />
    </div>
</template>
<script>
import { mapUserData } from "@/utils";
import Sovendus from "@/components/Sovendus";

export default {
    mounted() {
        this.$ga.pageview()
    },
    components: { Sovendus },
    computed: {
        ...mapUserData(["importo", "name"]),
        type() {
            return this.importo > 2000 ? 'Prestito Personale' : 'Power Credit';
        }
    },
    beforeRouteLeave(to, from, next) {
        this.$refs.sovendus?.$emit('routeLeave');
        next()
    }
};
</script>